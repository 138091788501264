var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabvertical" },
    [
      _c(_vm.componentAssess, {
        tag: "component",
        attrs: {
          height: _vm.tabHeight,
          workPermit: _vm.workPermit,
          supWorks: _vm.supWorks,
          saveCall: _vm.saveCall,
          isWriting: _vm.isWriting,
        },
        on: {
          "update:workPermit": function ($event) {
            _vm.workPermit = $event
          },
          "update:work-permit": function ($event) {
            _vm.workPermit = $event
          },
          "update:supWorks": function ($event) {
            _vm.supWorks = $event
          },
          "update:sup-works": function ($event) {
            _vm.supWorks = $event
          },
          "update:saveCall": function ($event) {
            _vm.saveCall = $event
          },
          "update:save-call": function ($event) {
            _vm.saveCall = $event
          },
        },
      }),
      _c(_vm.componentAttach, {
        tag: "component",
        attrs: {
          height: _vm.tabHeight,
          workPermit: _vm.workPermit,
          supWorks: _vm.supWorks,
          saveCall: _vm.saveCall,
          isWriting: _vm.isWriting,
        },
        on: {
          "update:workPermit": function ($event) {
            _vm.workPermit = $event
          },
          "update:work-permit": function ($event) {
            _vm.workPermit = $event
          },
          "update:supWorks": function ($event) {
            _vm.supWorks = $event
          },
          "update:sup-works": function ($event) {
            _vm.supWorks = $event
          },
          "update:saveCall": function ($event) {
            _vm.saveCall = $event
          },
          "update:save-call": function ($event) {
            _vm.saveCall = $event
          },
        },
      }),
      _c(_vm.componentWorker, {
        tag: "component",
        attrs: {
          height: _vm.tabHeight,
          workPermit: _vm.workPermit,
          supWorks: _vm.supWorks,
          saveCall: _vm.saveCall,
          isWriting: _vm.isWriting,
        },
        on: {
          "update:workPermit": function ($event) {
            _vm.workPermit = $event
          },
          "update:work-permit": function ($event) {
            _vm.workPermit = $event
          },
          "update:supWorks": function ($event) {
            _vm.supWorks = $event
          },
          "update:sup-works": function ($event) {
            _vm.supWorks = $event
          },
          "update:saveCall": function ($event) {
            _vm.saveCall = $event
          },
          "update:save-call": function ($event) {
            _vm.saveCall = $event
          },
        },
      }),
      _c(_vm.componentLoto, {
        tag: "component",
        attrs: {
          height: _vm.tabHeight,
          workPermit: _vm.workPermit,
          supWorks: _vm.supWorks,
          gridLoto: _vm.gridLoto,
          saveCall: _vm.saveCall,
          isWriting: _vm.isWriting,
        },
        on: {
          "update:workPermit": function ($event) {
            _vm.workPermit = $event
          },
          "update:work-permit": function ($event) {
            _vm.workPermit = $event
          },
          "update:supWorks": function ($event) {
            _vm.supWorks = $event
          },
          "update:sup-works": function ($event) {
            _vm.supWorks = $event
          },
          "update:gridLoto": function ($event) {
            _vm.gridLoto = $event
          },
          "update:grid-loto": function ($event) {
            _vm.gridLoto = $event
          },
          "update:saveCall": function ($event) {
            _vm.saveCall = $event
          },
          "update:save-call": function ($event) {
            _vm.saveCall = $event
          },
        },
      }),
      _c(_vm.componentHeavyEquip, {
        tag: "component",
        attrs: {
          height: _vm.tabHeight,
          workPermit: _vm.workPermit,
          supWorks: _vm.supWorks,
          gridHeavyEquip: _vm.gridHeavyEquip,
          saveCall: _vm.saveCall,
          isWriting: _vm.isWriting,
        },
        on: {
          "update:workPermit": function ($event) {
            _vm.workPermit = $event
          },
          "update:work-permit": function ($event) {
            _vm.workPermit = $event
          },
          "update:supWorks": function ($event) {
            _vm.supWorks = $event
          },
          "update:sup-works": function ($event) {
            _vm.supWorks = $event
          },
          "update:gridHeavyEquip": function ($event) {
            _vm.gridHeavyEquip = $event
          },
          "update:grid-heavy-equip": function ($event) {
            _vm.gridHeavyEquip = $event
          },
          "update:saveCall": function ($event) {
            _vm.saveCall = $event
          },
          "update:save-call": function ($event) {
            _vm.saveCall = $event
          },
        },
      }),
      _c(_vm.componentMap, {
        tag: "component",
        attrs: {
          height: _vm.tabHeight,
          workPermit: _vm.workPermit,
          supWorks: _vm.supWorks,
          saveCall: _vm.saveCall,
          isWriting: _vm.isWriting,
        },
        on: {
          "update:workPermit": function ($event) {
            _vm.workPermit = $event
          },
          "update:work-permit": function ($event) {
            _vm.workPermit = $event
          },
          "update:supWorks": function ($event) {
            _vm.supWorks = $event
          },
          "update:sup-works": function ($event) {
            _vm.supWorks = $event
          },
          "update:saveCall": function ($event) {
            _vm.saveCall = $event
          },
          "update:save-call": function ($event) {
            _vm.saveCall = $event
          },
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }